const SERVICEWECHAT_URL = 'https://servicewechat.com';
const MP_URL = 'https://mp.weixin.qq.com';

// console.log('process.env', process.env)

const urlConfigs = {
  createtrialschool: SERVICEWECHAT_URL + '/wxa-edu-logic/createtrialschool',
  createtrialstudent: SERVICEWECHAT_URL + '/wxa-edu-logic/createtrialstudent',
  edudev_sendsmsverifycode:
    SERVICEWECHAT_URL + '/wxa-edu-logic/edudev_sendsmsverifycode',
  edudev_checksmsverifycode:
    SERVICEWECHAT_URL + '/wxa-edu-logic/edudev_checksmsverifycode',
  loginbyaccount: SERVICEWECHAT_URL + '/wxa-edu-logic/loginbyaccount',
  edudev_signupcompetition:
    SERVICEWECHAT_URL + '/wxa-edu-logic/edudev_signupcompetition',
  edudev_getparticipationinfo:
    SERVICEWECHAT_URL + '/wxa-edu-logic/edudev_getparticipationinfo',

  selectappversionparticipate:
    SERVICEWECHAT_URL + '/wxa-edu-logic/selectappversionparticipate',
  uploadparticipateattachment:
    SERVICEWECHAT_URL + '/wxa-edu-logic/uploadparticipateattachment',
  getparticipateattachment:
    SERVICEWECHAT_URL + '/wxa-edu-logic/getparticipateattachment',

  get_student_appinfo:
    SERVICEWECHAT_URL +
    '/wxa-dev-logic/educationdev?action=get_student_appinfo',
  get_school_appdetail:
    SERVICEWECHAT_URL +
    '/wxa-dev-logic/educationdev?action=get_school_appdetail ',
  get_app_commitinfo:
    SERVICEWECHAT_URL + '/wxa-dev-logic/educationdev?action=get_app_commitinfo',

  check_account_name: SERVICEWECHAT_URL + '/wxa-edu-logic/checkaccountname',

  basesafeqrcode: MP_URL + '/cgi-bin/basesafeqrcode',
};

export default urlConfigs;
// /wxa-edu-logic/createtrialschool
// message CreateTrialSchoolReq {
//     optional string qrcode_ticket = 1;
//     optional string school_name = 2;
//     optional string teacher_name = 3;
//     optional uint64 sub_cls_bits    = 4; // 学科分类
//     optional uint64 period_bits     = 5; // 学段分类
//     optional string phone_number = 6;
//     string verify_code
// }

// message CreateTrialSchoolResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
//     optional string teacher_account_name = 3;
//     optional string teacher_account_pwd = 4;
//     optional string student_account_name = 5;
//     optional string student_account_pwd = 6;
// }

// /wxa-edu-logic/createtrialstudent
// message CreateTrialStudentReq {
//     optional string student_name = 1;
//     optional string phone_number = 2;
//     string verify_code
// }

// message CreateTrialStudentResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
//     optional string account_name = 3;
//     optional string account_pwd = 4;
// }

// cgi: /wxa-edu-logic/edudev_sendsmsverifycode
// message SendSmsVerifyCodeReq {
//     optional string phone_number = 1;
// }

// message SendSmsVerifyCodeResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
// }

// cgi: /wxa-edu-logic/edudev_checksmsverifycode
// message CheckWxaEduSMSVerifyCodeReq {
//     optional string phone_number = 1;
//     optional string verify_code = 2;
// }

// message CheckWxaEduSMSVerifyCodeResp {
// }

// /wxa-edu-logic/teacherlogin
// // 教师扫码
// message TeacherLoginReq {
// }
// message TeacherLoginResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
//     optional string eduticket = 3;
//     optional uint32 eduticket_expire_time = 4;
// }

// /wxa-edu-logic/loginbyaccount
// // 教师学生账号密码登录
// message LoginByAccountReq {
//     optional string account_name = 1;
//     optional string account_pwd = 2;
// }
// message LoginByAccountResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
//     optional uint32 account_type = 3;
//     optional string eduticket = 4;
//     optional uint32 eduticket_expire_time = 5;
//     optional string test_ticket = 6;
//     optional string test_ticket_expire_time = 7;
//     optional bool is_bind = 8 [default = false];

// }

// /wxa-edu-logic/refresheduticket
// // 刷新ticket
// message RefreshEduTicketReq {
// }
// message RefreshEduTicketResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
//     optional string eduticket = 3;
//     optional uint32 eduticket_expire_time = 4;
//     optional string test_ticket = 5;
//     optional string test_ticket_expire_time = 6;
// }

// /wxa-edu-logic/teacherbinduuid
// // 教师旧帐号绑定uuid
// message TeacherBindUuidReq {
//     optional string account_name = 1;
//     optional string account_pwd = 2;
// }
// message TeacherBindUuidResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
//     optional string eduticket = 3;
//     optional uint32 eduticket_expire_time = 4;
// }

// /wxa-edu-logic/checkrelationofuuidanduseruin
// // 检查教师输入的账号密码得到的uuid和扫码得到的useruin是否一致
// message CheckRelationOfUuidAndUseruinReq {
// }
// message CheckRelationOfUuidAndUseruinResp {
//     optional int32 errcode = 1;
//     optional string errmsg = 2;
// }

// // 用到的错误码
// kEduErr_TeacherAlreadyBind = 100131, // 教师已绑定uuid
// kEduErr_TeacherNotBind = 100132, // 教师未绑定uuid
// kEduErr_InvalidAccountPwd = 100136, // 密码错误
// kEduErr_UuidAlreadyBind = 100137, // uuid已被其他教师绑定
// kEduErr_UuidAndUseruinNotMatch = 100140, // 账密得到的uuid和扫码得到的useruin不匹配

// 已上线，以下是url需要附带的信息：
// teacherlogin newticket
// loginbyaccount	  无

// refresheduticket  newticket eduticket
// teacherbinduuid  newticket
// checkrelationofuuidanduseruin	  newticket eduticket
