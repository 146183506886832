import { useEffect, useState } from 'react';
import '../Official.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeviceStore } from '../../../store';
import RegistDialog from '../../../components/Dialog/RegistDialog';
import { CopyRightMobile } from '../../login/mobile/Login';
import DownloadDialog from '../../../components/Dialog/DownloadDialog';
import { FormattedMessage } from 'react-intl';
import OfficialTextBlock from '../../../components/OfficialTextBlock/index';
import PhotoBlock from '../../../components/OfficialPhotoBlock/index';
import { Player } from 'video-react';

const OfficialMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const preURL = process.env.PUBLIC_URL;

  useEffect(() => {
    const params: Record<string, string | null> = {};
    const queryString = window.location.search.substring(1);
    const vars = queryString.split('&');

    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=');
      if (pair.length === 2) {
        params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }
      if (params.action && params.action === 'regist') {
        // 移动端不应该给注册弹窗
        // setShowRegistDialog(true)
      }

      if (params.action && params.action === 'download') {
        // 移动端不应该给下载弹窗
        // setShowDownloadDialog(true)
      }
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes('edu.weixin.qq.com/competition')) {
      navigate('/competition');
    } else if (window.location.href.includes('edu.weixin.qq.com/rewardList')) {
      navigate('/rewardList');
    } else if (
      window.location.href.includes('edu.weixin.qq.com/eventDetails')
    ) {
      navigate('/eventDetails');
    }
  }, [location, navigate]);

  return (
    <div
      className="OfficialContainer"
      style={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <div
        style={{
          height: '450px',
          width: '100%',
          backgroundImage: `url('${preURL}/img/platformBackgroundSmall.png')`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100% 100%',
          position: 'absolute',
        }}
      ></div>
      <div
        style={{ width: '100%', height: '100px', position: 'absolute', top: 0 }}
      >
        <div
          style={{
            margin: '0 16px',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={`${preURL}/img/platformImage.png`}
            style={{ width: '267px' }}
            alt=""
          />
        </div>

        <div
          style={{
            margin: '26px 16px 48px 16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '26px',
          }}
        >
          <div
            style={{
              fontSize: '22px',
              marginBottom: '8px',
              fontWeight: '500',
              color: 'rgba(0, 0, 0, 0.9)',
            }}
          >
            <FormattedMessage
              id="Official.slogan1"
              description="小程序, 学以致用"
              defaultMessage="小程序, 学以致用"
            />
          </div>
          <div
            style={{
              fontSize: '14px',
              fontWeight: '400',
              color: 'rgba(0, 0, 0, 0.55)',
            }}
          >
            <FormattedMessage
              id="Official.slogan2"
              description="观察、思考、创造、改变"
              defaultMessage="观察、思考、创造、改变"
            />
          </div>
          <div className="official-mobile__regist-tip">
            <p>
              <FormattedMessage
                id="Official.applyBeta"
                description="如需注册账号，请通过电脑端打开小程序教育平台官网"
                defaultMessage="如需注册账号，请通过电脑端打开小程序教育平台官网"
              />
            </p>
            <p>(edu.weixin.qq.com)</p>
          </div>
        </div>

        <div
          style={{
            margin: '0 16px',
            borderRadius: '12px',
            backgroundColor: 'white',
            overflow: 'hidden',
            border: '0.5px solid rgba(7,193,96,0.15)',
          }}
        >
          <video
            id="video"
            src={`${preURL}/img/video.mov`}
            style={{ width: '100%' }}
            autoPlay
            loop
            playsInline
            controls
            muted
          ></video>

          <div style={{ width: '100%', padding: '20px' }}>
            <div
              style={{
                fontSize: '15px',
                fontWeight: '500',
                color: 'rgba(0,0,0,0.9)',
              }}
            >
              <FormattedMessage
                id="Official.solveProblemTitle"
                description="看看同学们如何自己开发小程序，解决生活中的问题。"
                defaultMessage="看看同学们如何自己开发小程序，解决生活中的问题。"
              />
            </div>
            <div style={{ marginTop: '8px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '24px',
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1914_504)">
                    <path
                      d="M16.6668 8.33342C16.6668 11.17 14.8953 13.5927 12.3982 14.5557H10.0001H7.60198C5.10493 13.5927 3.33344 11.17 3.33344 8.33342C3.33344 4.6515 6.31818 1.66675 10.0001 1.66675C13.682 1.66675 16.6668 4.6515 16.6668 8.33342Z"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.3979 14.5557L12.1149 17.9513C12.0969 18.1672 11.9164 18.3333 11.6996 18.3333H8.29977C8.08306 18.3333 7.90252 18.1672 7.88456 17.9513L7.60156 14.5557"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.0001 11.5105V9.948C11.3808 9.948 12.5001 8.89866 12.5001 7.60425C12.5001 6.30983 11.3808 5.2605 10.0001 5.2605C8.61935 5.2605 7.50006 6.30983 7.50006 7.60425"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1914_504">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  style={{
                    fontSize: '15px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.7)',
                    marginLeft: '8px',
                  }}
                >
                  <FormattedMessage
                    id="Official.problem1"
                    description="花园如何科学浇水"
                    defaultMessage="花园如何科学浇水"
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  margin: '8px 0',
                  alignItems: 'center',
                  height: '24px',
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1914_504)">
                    <path
                      d="M16.6668 8.33342C16.6668 11.17 14.8953 13.5927 12.3982 14.5557H10.0001H7.60198C5.10493 13.5927 3.33344 11.17 3.33344 8.33342C3.33344 4.6515 6.31818 1.66675 10.0001 1.66675C13.682 1.66675 16.6668 4.6515 16.6668 8.33342Z"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.3979 14.5557L12.1149 17.9513C12.0969 18.1672 11.9164 18.3333 11.6996 18.3333H8.29977C8.08306 18.3333 7.90252 18.1672 7.88456 17.9513L7.60156 14.5557"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.0001 11.5105V9.948C11.3808 9.948 12.5001 8.89866 12.5001 7.60425C12.5001 6.30983 11.3808 5.2605 10.0001 5.2605C8.61935 5.2605 7.50006 6.30983 7.50006 7.60425"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1914_504">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <div
                  style={{
                    fontSize: '15px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.7)',
                    marginLeft: '8px',
                  }}
                >
                  <FormattedMessage
                    id="Official.problem2"
                    description="食物浪费怎么办"
                    defaultMessage="食物浪费怎么办"
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '24px',
                }}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_1914_504)">
                    <path
                      d="M16.6668 8.33342C16.6668 11.17 14.8953 13.5927 12.3982 14.5557H10.0001H7.60198C5.10493 13.5927 3.33344 11.17 3.33344 8.33342C3.33344 4.6515 6.31818 1.66675 10.0001 1.66675C13.682 1.66675 16.6668 4.6515 16.6668 8.33342Z"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.3979 14.5557L12.1149 17.9513C12.0969 18.1672 11.9164 18.3333 11.6996 18.3333H8.29977C8.08306 18.3333 7.90252 18.1672 7.88456 17.9513L7.60156 14.5557"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.0001 11.5105V9.948C11.3808 9.948 12.5001 8.89866 12.5001 7.60425C12.5001 6.30983 11.3808 5.2605 10.0001 5.2605C8.61935 5.2605 7.50006 6.30983 7.50006 7.60425"
                      stroke="#FFC300"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1914_504">
                      <rect
                        width="20"
                        height="20"
                        fill="white"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div
                  style={{
                    fontSize: '15px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.7)',
                    marginLeft: '8px',
                  }}
                >
                  <FormattedMessage
                    id="Official.problem3"
                    description="校运会成绩在哪看"
                    defaultMessage="校运会成绩在哪看"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <OfficialTextBlock />

        <PhotoBlock />

        <img
          src={`${preURL}/img/bannerWithoutTitle.png`}
          alt=""
          style={{
            margin: '32px 0 40px 0',
            padding: '0 16px',
            cursor: 'pointer',
            borderRadius: '12px',
          }}
          onClick={() => {
            navigate('/competition');
          }}
        />

        <div>
          <CopyRightMobile />
        </div>

        <div style={{ height: '50px' }}></div>
      </div>
    </div>
  );
};

export default OfficialMobile;
