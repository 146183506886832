import MultiEndedHoc from '../../components/MultiEndedHoc';
import { PhotoBlockDesktop } from './desktop/index';
import { PhotoBlockMobile } from './mobile/index';

const PhotoBlock = () => {
  const WrappedComponent = MultiEndedHoc(PhotoBlockDesktop, PhotoBlockMobile);
  return <WrappedComponent />;
};

export default PhotoBlock;
