import React, {
  ChangeEvent,
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import '../baseStyle.css';
import './dialog.css';
import axios from 'axios';
import urlConfigs from '../../config/urlList';
import { REPORT_ENUM, sendLog } from '../../config/report';
import { debounce, sha256 } from '../../config/utils';
import {
  login,
  useDeviceStore,
  useDialogStore,
  useLanguageStore,
} from '../../store';
import { FormattedMessage, useIntl } from 'react-intl';
import { EduSvgCollection } from '../SvgManager';
import { DIALOG_ID } from '../../typing/global';
import classNames from 'classnames';

/* eslint-disable @typescript-eslint/no-unused-vars, no-empty, no-unsafe-optional-chaining */
interface IRegistDialogProps {
  // screenWidth: number
  // setDownloadtype: (platform: any) => void
}

interface IStudentForm {
  schoolName?: string;
  studentName?: string;
  phoneNumber: string;
  verifiCode?: string;
  aggreProtocol: boolean;
}

interface ITeacherForm {
  schoolName?: string;
  teacherName?: string;
  areaCode?: string;
  phoneNumber: string;
  verifiCode?: string;
  subjectList?: string[];
  gradeList?: string[];
  aggreProtocol: boolean;
}

interface IUserForm {
  accountName: string;
  accountPwd: string;
  confirmPwd: string;
}

type qrCodeStatus = 'scaning' | 'scanSuccess' | 'canceled' | 'done';

interface StepLayoutProps {
  children: ReactNode;
  onClick: () => void;
  header: string;
  title: string;
}

const enum REGISTER_PHASE {
  SELECT_IDENTITY = 1,
  SCAN_QR_CODE = 2,
  ACCOUNT_INFO = 3,
  FILL_INFO = 4,
  SUCCESS = 5,
  FAILED = 6,
}

function StepLayout(props: StepLayoutProps) {
  const { children, onClick, header, title } = props;
  return (
    <div className="regist-dialog__step-layout">
      <div className="regist-dialog__step-layout__header">
        <p style={{ fontSize: '20px', fontWeight: '500' }}>{header}</p>
        <EduSvgCollection.Close
          onClick={onClick}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <p className="regist-dialog__step-layout__title">{title}</p>
      {children}
    </div>
  );
}

export default function RegistDialog(props: IRegistDialogProps) {
  const preURL = process.env.PUBLIC_URL;

  const [selectedIdentity, setSelectedIdentity] = useState<
    'teacher' | 'student' | ''
  >('');
  const [step, setStep] = useState<REGISTER_PHASE>(
    REGISTER_PHASE.SELECT_IDENTITY,
  );

  let checkingAccountName: boolean = false;
  const [qrcodeStatus, setQrcodeStatus] = useState<qrCodeStatus>('scaning');

  const [registed, setRegisted] = useState(false);
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [areaCode, setAreaCode] = useState(AREA_CODE['Chinese Mainland']);

  const [seconds, setSeconds] = useState(0);
  const [qrTicket, setQrTicket] = useState('');

  const intl = useIntl();

  const { screenWidth } = useDeviceStore();

  const dialogStore = useDialogStore((state) => state);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  const jumpToDownload = () => {
    if (selectedIdentity === 'student') {
      sendLog({ method: '官网学生在注册成功页下载工具', user_id: account });
    } else {
      sendLog({ method: '官网老师在注册成功页下载工具', user_id: account });
    }
    dialogStore.closeDialog(DIALOG_ID.REGIST);

    dialogStore.openDialog(DIALOG_ID.DOWNLOAD);
  };

  useEffect(() => {
    setStep(() => {
      if (qrcodeStatus === 'done' && qrTicket !== '') {
        return REGISTER_PHASE.ACCOUNT_INFO;
      }

      if (selectedIdentity == '') {
        return REGISTER_PHASE.SELECT_IDENTITY;
      }

      return REGISTER_PHASE.SCAN_QR_CODE;
    });
  }, [qrcodeStatus]);

  useEffect(() => {
    if (seconds === 0) return;

    const intervalId = setTimeout(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [seconds]);

  const [studentFormDisabled, setStudentFormDisabled] = useState(true);
  const [teacherFormDisabled, setTeacherFormDisabled] = useState(true);

  const subjectOptions = [
    intl.formatMessage({ id: 'subject.it' }),
    intl.formatMessage({ id: 'subject.math' }),
    intl.formatMessage({ id: 'subject.chinese' }),
    intl.formatMessage({ id: 'subject.english' }),
    intl.formatMessage({ id: 'subject.physic' }),
    intl.formatMessage({ id: 'subject.chemistry' }),
    intl.formatMessage({ id: 'subject.biology' }),
    intl.formatMessage({ id: 'subject.science' }),
    intl.formatMessage({ id: 'subject.pe' }),
    intl.formatMessage({ id: 'subject.art' }),
    intl.formatMessage({ id: 'subject.history' }),
    intl.formatMessage({ id: 'subject.geography' }),
    intl.formatMessage({ id: 'subject.morality' }),
    intl.formatMessage({ id: 'subject.labor' }),
    intl.formatMessage({ id: 'subject.general' }),
    intl.formatMessage({ id: 'subject.campusApp' }),
  ];

  const gradeOptions = [
    intl.formatMessage({ id: 'grade.grade1to3' }),
    intl.formatMessage({ id: 'grade.grade4to6' }),
    intl.formatMessage({ id: 'grade.middleSchool' }),
    intl.formatMessage({ id: 'grade.highSchool' }),
    intl.formatMessage({ id: 'grade.vocationalEducation' }),
    intl.formatMessage({ id: 'grade.higherEducation' }),
  ];

  const [studentForm, setStudentForm] = useState<IStudentForm>({
    schoolName: '',
    studentName: '',
    phoneNumber: '',
    verifiCode: '',
    aggreProtocol: false,
  });
  const [teacherForm, setTeacherForm] = useState<ITeacherForm>({
    schoolName: '',
    teacherName: '',
    phoneNumber: '',
    verifiCode: '',
    subjectList: [],
    gradeList: [],
    aggreProtocol: false,
  });

  const [userForm, setUserForm] = useState<IUserForm>({
    accountName: '',
    accountPwd: '',
    confirmPwd: '',
  });

  const [formError, setFormError] = useState({
    schoolName: '',
    studentName: '',
    teacherName: '',
    phoneNumber: '',
    accountName: '',
    confirmPwd: '',
    verifiCode: '',
    subjectList: '',
    gradeList: '',
    aggreProtocol: '',
  });

  const handleInputChange = (
    identity: 'teacher' | 'student' | 'user',
    item: string,
    val: string,
  ) => {
    if (identity === 'user') {
      if (item === 'accountName') {
        debounce(checkAccountName, 100)(val);
      }
      if (item === 'accountPwd') {
        setUserForm((prev) => ({
          ...prev,
          confirmPwd: '',
        }));
        setFormError((prev) => ({
          ...prev,
          confirmPwd: '',
        }));
      }
      setUserForm((prev) => ({
        ...prev,
        [item]: val,
      }));
    }

    if (identity === 'student') {
      if (studentForm?.[item as keyof IStudentForm] === undefined) return;
      setStudentForm((prev) => ({
        ...prev,
        [item]: val,
      }));
    }

    if (identity === 'teacher') {
      if (teacherForm?.[item as keyof ITeacherForm] === undefined) return;
      setTeacherForm((prev) => ({
        ...prev,
        [item]: val,
      }));
    }

    if (item === 'phoneNumber') {
      if (!checkPhonenumberFormat(val)) {
        setFormError({
          ...formError,
          phoneNumber: intl.formatMessage({
            id: 'registDialog.enterCorrectPhoneNumber',
          }),
        });
      } else {
        setFormError({ ...formError, phoneNumber: '' });
      }
    }

    if (item === 'confirmPwd') {
      if (checkConfirmPassword(item, val)) {
        setFormError((prev) => ({
          ...prev,
          confirmPwd: '',
        }));
      } else {
        setFormError((prev) => ({
          ...prev,
          confirmPwd: intl.formatMessage({
            id: 'registDialog.confirmPasswordError',
          }),
        }));
      }
    }
  };

  const handleCheckBoxChange = (identity: 'teacher' | 'student') => {
    if (identity === 'student') {
      setStudentForm((prev) => ({
        ...prev,
        aggreProtocol: !prev.aggreProtocol,
      }));
    }

    if (identity === 'teacher') {
      setTeacherForm((prev) => ({
        ...prev,
        aggreProtocol: !prev.aggreProtocol,
      }));
    }
  };

  const handleMultilpleSelect = (
    identity: 'teacher' | 'student',
    item: keyof ITeacherForm,
    val: string,
  ) => {
    if (identity === 'teacher') {
      const teacherItem = teacherForm[item];
      if (teacherItem === undefined) {
        return;
      }
      let newArr: string[] = [];
      if (Array.isArray(teacherItem)) {
        newArr = [...teacherItem];
        if (teacherItem.includes(val)) {
          const indexToRemove = teacherItem.indexOf(val);
          newArr.splice(indexToRemove, 1);
        } else {
          newArr.push(val);
        }
      }
      setTeacherForm((prev) => ({
        ...prev,
        [item]: newArr,
      }));
    }
  };

  const sendsmsverifycode = async (identity: 'teacher' | 'student') => {
    const obj = {
      schoolName: '',
      studentName: '',
      teacherName: '',
      phoneNumber: '',
      accountName: '',
      confirmPwd: '',
      verifiCode: '',
      subjectList: '',
      gradeList: '',
      aggreProtocol: '',
    };
    const data = {
      phone_number: '',
    };
    if (identity === 'student') {
      if (studentForm.phoneNumber) {
        data.phone_number = studentForm.phoneNumber;
      }
    } else {
      if (teacherForm.phoneNumber) {
        data.phone_number = teacherForm.phoneNumber;
      }
    }

    if (data.phone_number === '') {
      obj.phoneNumber = intl.formatMessage({
        id: 'registDialog.phoneNumberBlank',
      });
      setFormError(obj);
      return;
    }

    if (!checkPhonenumberFormat(data.phone_number)) {
      obj.phoneNumber = intl.formatMessage({
        id: 'registDialog.checkPhoneNumber',
      });
      setFormError(obj);
      return;
    }

    const resp = await axios.post(
      urlConfigs.edudev_checksmsverifycode,
      JSON.stringify({
        verify_code: '',
        phone_number: areaCode + '-' + data.phone_number,
      }),
    );
    // 根据resp，抛错，return
    if (resp.data.errcode == 100143) {
      obj.verifiCode = intl.formatMessage({
        id: 'registDialog.verifyCodeLimit',
      });
      setFormError(obj);
      // setFormError({ ...formError, verifiCode: '验证码发送次数受限，请稍后再试' })
      return;
    }

    setFormError(obj);

    setSeconds(60);
    await axios.post(urlConfigs.edudev_sendsmsverifycode, JSON.stringify(data));
  };

  const checkAccountName = (val: string) => {
    checkingAccountName = true;
    axios
      .post(
        urlConfigs.check_account_name,
        JSON.stringify({ account_name: val }),
      )
      .then((resp) => {
        switch (resp.data.errcode) {
          // 0：账户名可用
          case 0: {
            setFormError({
              ...formError,
              accountName: '',
            });
            return;
          }
          // 100116：账户重复
          case 100116: {
            setFormError({
              ...formError,
              accountName: intl.formatMessage({
                id: 'registDialog.accountAlreadyExist',
              }),
            });
            return;
          }
          // 其余异常
          // todo：这里是否需要增添用户名不合法校验，例如有关黄赌政暴等
          default:
            console.log('Account check error');
            setFormError({
              ...formError,
              accountName: '',
            });
        }
      })
      .finally(() => {
        checkingAccountName = false;
      });
  };

  const regist = async (identity: 'teacher' | 'student') => {
    // 重置错误
    let obj = {
      schoolName: '',
      studentName: '',
      teacherName: '',
      phoneNumber: '',
      accountName: '',
      confirmPwd: '',
      verifiCode: '',
      subjectList: '',
      gradeList: '',
      aggreProtocol: '',
    };
    if (identity === 'student') {
      if (!checkPhonenumberFormat(studentForm.phoneNumber)) {
        obj.phoneNumber = intl.formatMessage({
          id: 'registDialog.checkPhoneNumber',
        });
        setFormError(obj);
        return;
      }
      if (studentForm.phoneNumber !== '' && studentForm.verifiCode !== '') {
        const resp = await axios.post(
          urlConfigs.edudev_checksmsverifycode,
          JSON.stringify({
            verify_code: studentForm.verifiCode,
            phone_number: studentForm.phoneNumber,
          }),
        );
        // 根据resp，抛错，return
        if (resp.data.errcode !== 0) {
          if (resp.data.errcode == 100143) {
            obj.verifiCode = intl.formatMessage({
              id: 'registDialog.verifyCodeLimit',
            });
            setFormError(obj);
            return;
          }
          obj.verifiCode = intl.formatMessage({
            id: 'registDialog.verifyCodeError',
          });
          setFormError(obj);
          return;
        }
      }
      setFormError(obj);
    } else {
      if (!checkPhonenumberFormat(teacherForm.phoneNumber)) {
        obj.phoneNumber = intl.formatMessage({
          id: 'registDialog.checkPhoneNumber',
        });
        setFormError(obj);
        return;
      }
      if (teacherForm.phoneNumber !== '' && teacherForm.verifiCode !== '') {
        const resp = await axios.post(
          urlConfigs.edudev_checksmsverifycode,
          JSON.stringify({
            verify_code: teacherForm.verifiCode,
            phone_number: teacherForm.phoneNumber,
          }),
        );
        // 根据resp，抛错，return
        if (resp.data.errcode !== 0) {
          if (resp.data.errcode == 100143) {
            obj.verifiCode = intl.formatMessage({
              id: 'registDialog.verifyCodeLimit',
            });
            setFormError(obj);
            return;
          }
          obj.verifiCode = intl.formatMessage({
            id: 'registDialog.verifyCodeError',
          });
          setFormError(obj);
          return;
        }
      }
      setFormError(obj);
    }

    // 注册
    if (identity === 'student') {
      const data = {
        student_name: studentForm.studentName,
        phone_number: areaCode + '-' + studentForm.phoneNumber,
        verify_code: studentForm.verifiCode,
        school_name: studentForm.schoolName,
        account_name: userForm.accountName, // 学生自定义账号名
        account_pwd: sha256(userForm.accountPwd), // 学生自定义密码
      };
      const resp = await axios.post(
        urlConfigs.createtrialstudent,
        JSON.stringify(data),
      );
      // console.log('resp', resp)
      if (resp.data.errcode === 0) {
        setAccount(resp.data?.account_name);
        setPassword(resp.data?.account_pwd);
        // 注册成功
        if (identity === 'student') {
          sendLog({
            method: REPORT_ENUM.STU_SIGNUP,
            user_id: resp.data?.account_name,
          });
        } else {
          sendLog({
            method: REPORT_ENUM.TEACHER_SIGNUP,
            user_id: resp.data?.account_name,
          });
        }
        setStep(REGISTER_PHASE.SUCCESS);
      } else {
        setStep(REGISTER_PHASE.FAILED);
      }
      // 根据resp，跳转step4 / step5
    } else {
      const sub_cls_bits = new Array(64).fill(0);
      const eriod_bits = new Array(64).fill(0);
      teacherForm.subjectList?.forEach((item) => {
        sub_cls_bits[
          subjectOptions.findIndex((options) => options === item) + 7
        ] = 1;
      });
      teacherForm.gradeList?.forEach((item) => {
        eriod_bits[gradeOptions.indexOf(item) + 2] = 1;
      });
      const data = {
        qrcode_ticket: qrTicket,
        school_name: teacherForm.schoolName,
        teacher_name: teacherForm.teacherName,
        sub_cls_bits: sub_cls_bits.reverse().join(''), // 学科分类
        eriod_bits: eriod_bits.reverse().join(''), // 学段分类
        phone_number: areaCode + '-' + teacherForm.phoneNumber,
        verify_code: teacherForm.verifiCode,
        teacher_account_name: userForm.accountName, // 老师自定义账号名
        teacher_account_pwd: sha256(userForm.accountPwd), // 老师自定义密码
      };

      const resp = await axios.post(
        urlConfigs.createtrialschool,
        JSON.stringify(data),
      );
      if (resp.data.errcode === 0) {
        setAccount(resp.data?.teacher_account_name);
        setPassword(resp.data?.teacher_account_pwd);
        setStep(REGISTER_PHASE.SUCCESS);
        // 清空账号密码
        setUserForm({
          accountName: '',
          accountPwd: '',
          confirmPwd: '',
        });
      } else {
        setStep(REGISTER_PHASE.FAILED);
      }
      // 根据resp，跳转step4 / step5
    }
  };

  const hideRegistLayout = () => {
    dialogStore.closeDialog(DIALOG_ID.REGIST);
  };

  const loginWhenRegisted = async () => {
    const data = {
      account_name: account,
      account_pwd: sha256(password),
    };
    const resp = await axios.post(
      urlConfigs.loginbyaccount,
      JSON.stringify(data),
    );
    if (resp.data.errcode === 0) {
      // 存到store里面
      login(
        resp.data.eduticket,
        resp.data.account_name,
        resp.data.account_type,
        Date.now() + resp.data.eduticket_expire_time * 1000,
      );
    }
  };

  // 手机号正则校验
  const checkPhonenumberFormat = (phoneNumber: string) => {
    switch (areaCode) {
      // 大陆
      case '+86': {
        const regexPhone_zh_cn = /^1[3-9]\d{9}$/;
        return regexPhone_zh_cn.test(phoneNumber);
      }
      // 香港
      case '+852': {
        const regexPhone_hk = /^(\d{8})$/;
        return regexPhone_hk.test(phoneNumber);
      }
      // 澳门
      case '+853': {
        const regexPhone_macao = /^(\d{8})$/;
        return regexPhone_macao.test(phoneNumber);
      }
      // 芬兰
      case '+358': {
        const regexPhone_macao = /^(\d{5,12})$/;
        return regexPhone_macao.test(phoneNumber);
      }
      // 英国
      case '+44': {
        const regexPhone_uk = /^(\d{9,10})$/;
        return regexPhone_uk.test(phoneNumber);
      }
      default: {
        return true;
      }
    }
  };

  const checkConfirmPassword = (item: string, val: string) => {
    if (item === 'confirmPwd') return userForm.accountPwd === val;
    return false;
  };

  useEffect(() => {
    if (step === REGISTER_PHASE.SUCCESS) {
      loginWhenRegisted();
    }
  }, [step]);
  useEffect(() => {
    setStudentFormDisabled(() => {
      if (
        studentForm.aggreProtocol &&
        studentForm.phoneNumber !== '' &&
        studentForm.schoolName !== '' &&
        studentForm.studentName !== '' &&
        studentForm.verifiCode !== ''
      ) {
        return false;
      }
      return true;
    });
  }, [studentForm]);

  useEffect(() => {
    setTeacherFormDisabled(() => {
      if (
        teacherForm.aggreProtocol &&
        teacherForm.gradeList?.length != 0 &&
        teacherForm.phoneNumber !== '' &&
        teacherForm.schoolName !== '' &&
        teacherForm.subjectList?.length != 0 &&
        teacherForm.teacherName !== '' &&
        teacherForm.verifiCode !== ''
      ) {
        return false;
      }
      return true;
    });
  }, [teacherForm]);

  const renderSubjectOptions = () => {
    const options = subjectOptions;
    const element =
      options &&
      options.map((item) => {
        return (
          <div
            onClick={() => {
              handleMultilpleSelect('teacher', 'subjectList', item);
            }}
            key={item}
            style={{
              flexBasis: '33.333%',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
              cursor: 'pointer',
            }}
          >
            <CheckBox
              value={
                teacherForm.subjectList
                  ? teacherForm.subjectList?.includes(item)
                  : false
              }
              onChange={() => {}}
            />
            <span
              style={{
                marginLeft: '8px',
                fontSize: '14px',
                color: 'rgba(0,0,0,0.9',
              }}
            >
              {item}
            </span>
          </div>
        );
      });
    return element;
  };
  const renderGradeOptions = () => {
    const options = gradeOptions;
    const element =
      options &&
      options.map((item) => {
        return (
          <div
            onClick={() => {
              handleMultilpleSelect('teacher', 'gradeList', item);
            }}
            key={item}
            style={{
              flexBasis: '100%',
              height: '36px',
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
              cursor: 'pointer',
            }}
          >
            <CheckBox
              value={
                teacherForm.gradeList
                  ? teacherForm.gradeList?.includes(item)
                  : false
              }
              onChange={() => {}}
            />
            <span
              style={{
                marginLeft: '8px',
                fontSize: '14px',
                color: 'rgba(0,0,0,0.9',
              }}
            >
              {item}
            </span>
          </div>
        );
      });
    return element;
  };

  const diableAccountInfo = () => {
    return (
      userForm.accountName === '' ||
      userForm.accountPwd === '' ||
      userForm.confirmPwd === '' ||
      formError.accountName !== '' ||
      formError.confirmPwd !== ''
    );
  };

  // 获取注册标题
  const STEP_TEXT_CONTENT = {
    [REGISTER_PHASE.SELECT_IDENTITY]: {
      header: intl.formatMessage({ id: 'registDialog.regist' }),
      title: {
        teacher: intl.formatMessage({ id: 'registDialog.step1' }),
        student: intl.formatMessage({ id: 'registDialog.step1' }),
      },
    },
    [REGISTER_PHASE.SCAN_QR_CODE]: {
      header:
        registed === false
          ? intl.formatMessage({ id: 'registDialog.regist' })
          : '',
      title: {
        teacher:
          registed === true
            ? ''
            : intl.formatMessage({ id: 'registDialog.teacherStep2' }),
        student: intl.formatMessage({ id: 'registDialog.teacherStep2' }),
      },
    },
    [REGISTER_PHASE.ACCOUNT_INFO]: {
      header: intl.formatMessage({ id: 'registDialog.regist' }),
      title: {
        teacher: intl.formatMessage({ id: 'registDialog.teacherStep3' }),
        student: intl.formatMessage({ id: 'registDialog.studentStep2' }),
      },
    },
    [REGISTER_PHASE.FILL_INFO]: {
      header: intl.formatMessage({ id: 'registDialog.regist' }),
      title: {
        teacher: intl.formatMessage({ id: 'registDialog.teacherStep4' }),
        student: intl.formatMessage({ id: 'registDialog.studentStep3' }),
      },
    },
    [REGISTER_PHASE.SUCCESS]: {
      header: '',
      title: {
        teacher: '',
        student: '',
      },
    },
    [REGISTER_PHASE.FAILED]: {
      header: '',
      title: {
        teacher: '',
        student: '',
      },
    },
  };

  const renderStepContent = (step: REGISTER_PHASE, identity: string) => {
    switch (step) {
      case REGISTER_PHASE.SELECT_IDENTITY:
        return (
          <>
            <Card
              identity="teacher"
              title={<FormattedMessage id="registDialog.teacher" />}
              subTitle={<FormattedMessage id="registDialog.teacherTarget" />}
              selected={selectedIdentity === 'teacher'}
              click={() => {
                setSelectedIdentity('teacher');
                sendLog({ method: '官网点击注册我是老师' });
              }}
            />
            <div style={{ height: '16px' }}></div>
            <Card
              identity="student"
              title={<FormattedMessage id="registDialog.student" />}
              subTitle={<FormattedMessage id="registDialog.studentTarget" />}
              selected={selectedIdentity === 'student'}
              click={() => {
                setSelectedIdentity('student');
                sendLog({ method: '官网点击注册我是学生' });
              }}
            />
            <div style={{ height: '68px' }}></div>

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                text={<FormattedMessage id="registDialog.cancel" />}
                onClick={hideRegistLayout}
              ></Button>
              <div style={{ width: '12px' }}></div>
              <Button
                backgroundColor="#21C187"
                color="white"
                text={<FormattedMessage id="registDialog.nextStep" />}
                disabled={selectedIdentity === ''}
                onClick={() => {
                  switch (identity) {
                    case 'teacher':
                      setStep(REGISTER_PHASE.SCAN_QR_CODE);
                      break;
                    case 'student':
                      setStep(REGISTER_PHASE.ACCOUNT_INFO);
                      break;
                  }
                }}
              ></Button>
            </div>
          </>
        );
      case REGISTER_PHASE.ACCOUNT_INFO:
        return (
          <>
            <Input
              errMsg={formError.accountName}
              desc={<FormattedMessage id="registDialog.registUsername" />}
              placeHolder={intl.formatMessage({
                id: 'registDialog.enterUsername',
              })}
              value={userForm.accountName}
              onChange={(e) => {
                handleInputChange('user', 'accountName', e.target.value);
              }}
            />
            <div style={{ height: '24px' }}></div>

            <Input
              type="password"
              desc={<FormattedMessage id="registDialog.registPassword" />}
              placeHolder={intl.formatMessage({
                id: 'registDialog.enterPassword',
              })}
              value={userForm.accountPwd}
              onChange={(e) => {
                handleInputChange('user', 'accountPwd', e.target.value);
              }}
            />
            <div style={{ height: '24px' }}></div>

            <Input
              type="password"
              errMsg={formError.confirmPwd}
              desc={
                <FormattedMessage id="registDialog.registConfirmPassword" />
              }
              placeHolder={intl.formatMessage({
                id: 'registDialog.enterConfirmPassword',
              })}
              value={userForm.confirmPwd}
              onChange={(e) => {
                handleInputChange('user', 'confirmPwd', e.target.value);
              }}
            />

            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '68px',
              }}
            >
              <Button
                text={<FormattedMessage id="registDialog.lastStep" />}
                onClick={() => {
                  switch (identity) {
                    case 'teacher':
                      setStep(REGISTER_PHASE.SCAN_QR_CODE);
                      break;
                    case 'student':
                      setStep(REGISTER_PHASE.SELECT_IDENTITY);
                      break;
                  }
                }}
              ></Button>
              <div style={{ width: '12px' }}></div>
              <Button
                backgroundColor="#21C187"
                color="white"
                text={<FormattedMessage id="registDialog.nextStep" />}
                disabled={diableAccountInfo()}
                onClick={() => {
                  // 避免用户在姓名校验的间隙直接跳到下一步，这里用setState做状态控制会有延迟，所以采用直接赋值的方式
                  if (checkingAccountName) return;
                  setStep(REGISTER_PHASE.FILL_INFO);
                }}
              ></Button>
            </div>
          </>
        );
      case REGISTER_PHASE.SCAN_QR_CODE:
        return (
          <>
            {!registed && (
              <>
                <QRCode
                  status={qrcodeStatus}
                  word={<FormattedMessage id="registDialog.qrCodeWord" />}
                  setStatus={setQrcodeStatus}
                  setTicket={setQrTicket}
                  ticket={qrTicket}
                  setRegisted={setRegisted}
                />

                <div style={{ height: '60px' }}></div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    text={<FormattedMessage id="registDialog.lastStep" />}
                    onClick={() => setStep(REGISTER_PHASE.SELECT_IDENTITY)}
                  ></Button>
                </div>
              </>
            )}
            {registed && (
              <div
                style={{
                  marginTop: '74px',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${preURL}/svg/done-circle.svg)`,
                    width: '64px',
                    height: '64px',
                  }}
                ></div>
                <div style={{ height: '24px' }}></div>
                <p
                  style={{
                    fontSize: '20px',
                    fontWeight: '500',
                    color: 'rgba(0,0,0,0.9)',
                  }}
                >
                  <FormattedMessage id="registDialog.platformRegisted" />
                </p>
                <div style={{ height: '8px' }}></div>
                <p
                  style={{
                    fontSize: '17px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  <FormattedMessage id="registDialog.downloadToolTip" />
                </p>
                <div style={{ height: '64px' }}></div>

                <Button
                  onClick={jumpToDownload}
                  text={<FormattedMessage id="registDialog.downloadTool" />}
                  width={120}
                  backgroundColor={'rgba(0,195,131,1)'}
                  color="white"
                ></Button>

                <div style={{ height: '98px' }}></div>
              </div>
            )}
          </>
        );
      case REGISTER_PHASE.FILL_INFO:
        if (identity === 'student') {
          return (
            <>
              <Input
                desc={<FormattedMessage id="registDialog.schoolName" />}
                placeHolder={intl.formatMessage({
                  id: 'registDialog.enterSchoolName',
                })}
                value={studentForm.schoolName}
                onChange={(e) => {
                  handleInputChange('student', 'schoolName', e.target.value);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <Input
                desc={<FormattedMessage id="registDialog.studentName" />}
                placeHolder={intl.formatMessage({
                  id: 'registDialog.enterStudentName',
                })}
                value={studentForm.studentName}
                onChange={(e) => {
                  handleInputChange('student', 'studentName', e.target.value);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <Input
                type="phone"
                errMsg={formError.phoneNumber}
                desc={<FormattedMessage id="registDialog.phoneNumber" />}
                placeHolder={intl.formatMessage({
                  id: 'registDialog.enterPhoneNumber',
                })}
                value={studentForm.phoneNumber}
                onChange={(e) => {
                  handleInputChange('student', 'phoneNumber', e.target.value);
                }}
                areaCode={areaCode}
                setAreaCode={(code: string) => {
                  setAreaCode(code);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <div
                style={{ display: 'flex', width: '100%', alignItems: 'center' }}
              >
                <Input
                  errMsg={formError.verifiCode}
                  desc={<FormattedMessage id="registDialog.verifyCode" />}
                  placeHolder={intl.formatMessage({
                    id: 'registDialog.enterVerifyCode',
                  })}
                  value={studentForm.verifiCode}
                  onChange={(e) => {
                    handleInputChange('student', 'verifiCode', e.target.value);
                  }}
                />

                {seconds === 0 ? (
                  <p
                    style={{
                      marginLeft: '12px',
                      flex: '0 0 70px',
                      fontSize: '14px',
                      color:
                        studentForm.phoneNumber !== ''
                          ? 'rgb(101,119,156)'
                          : 'rgba(0,0,0,0.3)',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sendsmsverifycode('student');
                    }}
                  >
                    <FormattedMessage id="registDialog.getVerifiCode" />
                  </p>
                ) : (
                  <p
                    style={{
                      marginLeft: '12px',
                      flex: '0 0 86px',
                      fontSize: '14px',
                      color: 'rgba(0,0,0,0.3)',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    {intl.formatMessage(
                      { id: 'registDialog.secondsLater' },
                      { seconds: seconds },
                    )}
                  </p>
                )}
              </div>
              <div style={{ height: '40px' }}></div>

              <div style={{ width: '100%' }}>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CheckBox
                    value={studentForm.aggreProtocol}
                    onChange={() => handleCheckBoxChange('student')}
                  />
                  <div style={{ width: '8px' }}></div>
                  <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.55)' }}>
                    <span
                      onClick={() => {
                        handleCheckBoxChange('student');
                      }}
                    >
                      <FormattedMessage id="registDialog.iHaveReadAndAgree" />
                    </span>
                    <span
                      style={{ color: 'rgb(101,119,156)', cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://developers.weixin.qq.com/community/develop/doc/00000003f645685129b1bd8106b809',
                          '_blank',
                        );
                      }}
                    >
                      <FormattedMessage id="registDialog.userServiceTerms" />
                    </span>
                    <span>
                      {' '}
                      <FormattedMessage id="registDialog.and" />{' '}
                    </span>
                    <span
                      style={{ color: 'rgb(101,119,156)', cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://developers.weixin.qq.com/community/develop/doc/000c8cd9b60348612ab1282f561409',
                          '_blank',
                        );
                      }}
                    >
                      <FormattedMessage id="registDialog.privacyGuidelines" />
                    </span>
                  </div>
                </div>
              </div>

              <div style={{ height: '32px' }}></div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  text={<FormattedMessage id="registDialog.lastStep" />}
                  onClick={() => setStep(REGISTER_PHASE.ACCOUNT_INFO)}
                ></Button>
                <div style={{ width: '12px' }}></div>
                <Button
                  text={<FormattedMessage id="registDialog.submit" />}
                  disabled={studentFormDisabled}
                  onClick={async () => {
                    await regist('student');
                  }}
                ></Button>
              </div>
            </>
          );
        } else if (identity === 'teacher') {
          return (
            <>
              <Input
                desc={intl.formatMessage({ id: 'registDialog.schoolName' })}
                placeHolder={intl.formatMessage({
                  id: 'registDialog.enterSchoolName',
                })}
                value={teacherForm.schoolName}
                onChange={(e) => {
                  handleInputChange('teacher', 'schoolName', e.target.value);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <Input
                desc={intl.formatMessage({ id: 'registDialog.teacherName' })}
                placeHolder={intl.formatMessage({
                  id: 'registDialog.enterTeacherName',
                })}
                value={teacherForm.teacherName}
                onChange={(e) => {
                  handleInputChange('teacher', 'teacherName', e.target.value);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <MultipleSelectInputBox
                desc={<FormattedMessage id="registDialog.subject" />}
                placeHolder={
                  <FormattedMessage id="registDialog.subjectPlaceholder" />
                }
                value={teacherForm.subjectList}
                displayNum={3}
                optsWidth={400}
                optsElements={renderSubjectOptions()}
                onChange={(item: string) => {
                  handleMultilpleSelect('teacher', 'subjectList', item);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <MultipleSelectInputBox
                desc={intl.formatMessage({ id: 'registDialog.grade' })}
                placeHolder={intl.formatMessage({
                  id: 'registDialog.gradePlaceholder',
                })}
                value={teacherForm.gradeList}
                displayNum={2}
                optsElements={renderGradeOptions()}
                onChange={(item: string) => {
                  handleMultilpleSelect('teacher', 'gradeList', item);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <Input
                errMsg={formError.phoneNumber}
                type="phone"
                desc={<FormattedMessage id="registDialog.phoneNumber" />}
                placeHolder={intl.formatMessage({
                  id: 'registDialog.enterPhoneNumber',
                })}
                value={teacherForm.phoneNumber}
                onChange={(e) => {
                  handleInputChange('teacher', 'phoneNumber', e.target.value);
                }}
                areaCode={areaCode}
                setAreaCode={(code: string) => {
                  setAreaCode(code);
                }}
              />
              <div style={{ height: '24px' }}></div>

              <div
                style={{ display: 'flex', width: '100%', alignItems: 'center' }}
              >
                <Input
                  errMsg={formError.verifiCode}
                  desc={<FormattedMessage id="registDialog.verifyCode" />}
                  placeHolder={intl.formatMessage({
                    id: 'registDialog.enterVerifyCode',
                  })}
                  value={teacherForm.verifiCode}
                  onChange={(e) => {
                    handleInputChange('teacher', 'verifiCode', e.target.value);
                  }}
                />
                <div style={{ flex: '0 0 12px' }}></div>
                {seconds === 0 ? (
                  <p
                    style={{
                      flex: '0 0 70px',
                      fontSize: '14px',
                      color:
                        teacherForm.phoneNumber !== ''
                          ? 'rgb(101,119,156)'
                          : 'rgba(0,0,0,0.3)',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      sendsmsverifycode('teacher');
                    }}
                  >
                    <FormattedMessage id="registDialog.getVerifiCode" />
                  </p>
                ) : (
                  <p
                    style={{
                      flex: '0 0 82px',
                      fontSize: '14px',
                      color: 'rgba(0,0,0,0.3)',
                      fontWeight: '400',
                      cursor: 'pointer',
                    }}
                  >
                    {intl.formatMessage(
                      { id: 'registDialog.secondsLater' },
                      { seconds: seconds },
                    )}
                  </p>
                )}
              </div>
              <div style={{ height: '40px' }}></div>

              <div style={{ width: '100%' }}>
                <div
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CheckBox
                    value={teacherForm.aggreProtocol}
                    onChange={() => handleCheckBoxChange('teacher')}
                  />
                  <div style={{ width: '8px' }}></div>
                  <div style={{ fontSize: '14px', color: 'rgba(0,0,0,0.55)' }}>
                    <span>
                      <FormattedMessage id="registDialog.iHaveReadAndAgree" />
                    </span>
                    <span
                      style={{ color: 'rgb(101,119,156)', cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://developers.weixin.qq.com/community/develop/doc/00000003f645685129b1bd8106b809',
                          '_blank',
                        );
                      }}
                    >
                      <FormattedMessage id="registDialog.userServiceTerms" />
                    </span>
                    <span>
                      <FormattedMessage id="registDialog.and" />
                    </span>
                    <span
                      style={{ color: 'rgb(101,119,156)', cursor: 'pointer' }}
                      onClick={() => {
                        window.open(
                          'https://developers.weixin.qq.com/community/develop/doc/000c8cd9b60348612ab1282f561409',
                          '_blank',
                        );
                      }}
                    >
                      <FormattedMessage id="registDialog.privacyGuidelines" />
                    </span>
                  </div>
                </div>
              </div>

              <div style={{ height: '32px' }}></div>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  text={<FormattedMessage id="registDialog.lastStep" />}
                  onClick={() => setStep(REGISTER_PHASE.SCAN_QR_CODE)}
                ></Button>
                <div style={{ width: '12px' }}></div>
                <Button
                  text={<FormattedMessage id="registDialog.submit" />}
                  disabled={teacherFormDisabled}
                  onClick={async () => {
                    // console.log(teacherForm)
                    await regist('teacher');
                  }}
                ></Button>
              </div>
            </>
          );
        } else {
          return null;
        }
      case REGISTER_PHASE.SUCCESS:
        return (
          <div
            style={{
              marginTop: '120px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundImage: `url(${preURL}/svg/check-circle2.svg)`,
                width: '64px',
                height: '64px',
              }}
            ></div>
            <div style={{ height: '24px' }}></div>

            <p
              style={{
                fontSize: '20px',
                fontWeight: '500',
                color: 'rgba(0,0,0,0.9)',
              }}
            >
              <FormattedMessage id="registDialog.registed" />
            </p>
            <div style={{ height: '8px' }}></div>

            <p
              style={{
                fontSize: '17px',
                fontWeight: '400',
                color: 'rgba(0,0,0,0.9)',
              }}
            >
              <span>
                <FormattedMessage id="registDialog.account" />：{account}
              </span>
            </p>
            <div style={{ height: '8px' }}></div>

            <p
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: 'rgba(0,0,0,0.55)',
              }}
            >
              <FormattedMessage id="registDialog.accountInfoSent" />
            </p>
            <div style={{ height: '64px' }}></div>

            <Button
              onClick={jumpToDownload}
              text={<FormattedMessage id="registDialog.downloadTool" />}
              width={120}
              backgroundColor={'rgba(0,195,131,1)'}
              color="white"
            ></Button>

            <div style={{ height: '148px' }}></div>
          </div>
        );
      case REGISTER_PHASE.FAILED:
        return (
          <div
            style={{
              marginTop: '60px',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                backgroundImage: `url(${preURL}/svg/info-circle.svg)`,
                width: '64px',
                height: '64px',
              }}
            ></div>
            <div style={{ height: '24px' }}></div>

            <p
              style={{
                fontSize: '20px',
                fontWeight: '500',
                color: 'rgba(0,0,0,0.9)',
              }}
            >
              <FormattedMessage id="registDialog.notRegist" />
            </p>
            <div style={{ height: '8px' }}></div>

            <p
              style={{
                fontSize: '17px',
                fontWeight: '400',
                color: 'rgba(0,0,0,0.5)',
              }}
            >
              <FormattedMessage id="registDialog.getHelp" />
            </p>
            <div style={{ height: '12px' }}></div>

            <div
              style={{
                width: '136px',
                height: '136px',
                backgroundColor: 'white',
                borderRadius: '7px',
                border: '0.5px solid rgba(0,0,0,0.1)',
              }}
            >
              <img
                src={`${preURL}/img/pc/qywxCode1.png`}
                alt=""
              />
            </div>
            <div style={{ height: '64px' }}></div>

            <Button
              onClick={hideRegistLayout}
              text={<FormattedMessage id="registDialog.ISee" />}
              width={120}
            ></Button>

            <div style={{ height: '88px' }}></div>
          </div>
        );
    }
  };
  return (
    <div className="regist-dialog dialog-top">
      <div style={{ scale: `${screenWidth > 1920 ? 1 : screenWidth / 1920}` }}>
        <StepLayout
          header={STEP_TEXT_CONTENT[step].header}
          title={
            selectedIdentity === ''
              ? intl.formatMessage({ id: 'registDialog.step1' })
              : STEP_TEXT_CONTENT[step].title[selectedIdentity]
          }
          onClick={hideRegistLayout}
        >
          {renderStepContent(step, selectedIdentity)}
        </StepLayout>
      </div>
    </div>
  );
}

interface ICardProps {
  identity: 'teacher' | 'student';
  title: string | ReactNode;
  subTitle: string | ReactNode;
  selected: boolean;
  click: () => void;
}

function Card(props: ICardProps) {
  const { identity, title, subTitle, selected, click } = props;

  return (
    <div
      className={classNames({
        'regist-dialog__card': true,
        'regist-dialog__card--select': selected,
      })}
      onClick={() => {
        click();
      }}
    >
      {identity === 'teacher' ? (
        selected === true ? (
          <EduSvgCollection.Teacher />
        ) : (
          <EduSvgCollection.TeacherGray />
        )
      ) : selected === true ? (
        <EduSvgCollection.Student />
      ) : (
        <EduSvgCollection.StudentGray />
      )}
      <div style={{ marginLeft: '14px' }}>
        <p
          style={{
            fontSize: '14px',
            fontWeight: '500',
            color: 'rgba(0,0,0,0.9)',
          }}
        >
          {title}
        </p>
        <div style={{ height: '2px' }}></div>
        <p
          style={{
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.3)',
          }}
        >
          {subTitle}
        </p>
      </div>
    </div>
  );
}

interface IButtonProps {
  text: string | ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  width?: number;
  backgroundColor?: string;
  color?: string;
}

export function Button(props: IButtonProps) {
  const { text, disabled, onClick, width, backgroundColor, color } = props;
  return (
    <div
      style={{
        width: width ? `${width}px` : '96px',
        height: '40px',
        cursor: disabled ? 'not-allowed' : 'pointer',
        backgroundColor: disabled
          ? 'rgba(0,0,0,0.03)'
          : backgroundColor
            ? backgroundColor
            : 'rgba(0,0,0,0.03)',
        color: disabled
          ? 'rgba(0,0,0,0.15)'
          : color
            ? color
            : 'rgba(0,0,0,0.9)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '8px',
        fontWeight: '500',
        fontSize: '17px',
      }}
      onClick={() => {
        if (disabled) {
          return;
        }
        if (onClick) {
          onClick();
        }
      }}
    >
      {text}
    </div>
  );
}

interface IInputProps {
  desc?: string | ReactNode;
  descWidth?: string;
  defaultValue?: string;
  placeHolder?: string;
  value?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  errMsg?: string;
  showOutline?: boolean;
  type?: string;
  areaCode?: string;
  setAreaCode?: (code: string) => void;
}

interface SingleSelectProps {
  value?: string;
  placeHolder?: string;
  optsElements: { [key: string]: string };
  onClick?: (code: string) => void;
}

const AREA_CODE = {
  'Chinese Mainland': '+86',
  'Hong Kong, China': '+852',
  'Macao, China': '+853',
  Finland: '+358',
  'United Kingdom': '+44',
};

function SingleSelect(props: SingleSelectProps) {
  const { value, placeHolder, optsElements, onClick } = props;
  const [focused, setFocused] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setFocused(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  return (
    <div
      ref={divRef}
      style={{ position: 'relative' }}
    >
      <div
        style={{
          background: 'none',
          paddingLeft: '12px',
          paddingRight: '12px',
          outline: 'none',
          margin: '0',
          marginRight: '10px',
          boxShadow: 'none',
          display: 'flex',
          alignItems: 'center',
          border: focused
            ? '1px solid rgba(7,193,96,0.3)'
            : '1px solid rgba(0,0,0,0.1)',
          borderRadius: '8px',
          width: '100px',
          height: '40px',
          color: 'rgba(0,0,0,0.9)',
          fontSize: '14px',
          fontWeight: '400',
        }}
        onClick={() => {
          setFocused(!focused);
        }}
      >
        {placeHolder && value?.length === 0 && (
          <span
            style={{
              fontSize: '14px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.3)',
              width: '100%',
            }}
          >
            {placeHolder}
          </span>
        )}
        {value && (
          <div style={{ width: '100%', display: 'flex' }}>
            <div
              style={{
                fontSize: '12px',
                height: '21px',
                color: 'rgba(0,0,0,0.9)',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '2px',
                marginRight: '4px',
                cursor: 'pointer',
              }}
            >
              <span>{value}</span>
            </div>
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <EduSvgCollection.ArrowLeft
            style={{ transform: focused ? 'rotate(270deg)' : 'rotate(90deg)' }}
          />
        </div>
      </div>
      {focused && (
        <div
          style={{
            position: 'absolute',
            top: '40px',
            backgroundColor: 'white',
            width: '200%',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px 8px',
            zIndex: '10',
          }}
        >
          {Object.entries(optsElements).map(([key, value]) => {
            return (
              <div
                key={'option_' + key}
                style={{
                  width: '100%',
                  display: 'grid',
                  gridTemplateColumns: '3fr 1fr',
                }}
                onClick={() => {
                  setFocused(false);
                  onClick && onClick(value);
                }}
              >
                <span>{key}</span>
                <span>{value}</span>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export function Input(props: IInputProps) {
  const {
    defaultValue,
    desc,
    placeHolder,
    value,
    onChange,
    descWidth,
    errMsg,
    showOutline,
    type,
    areaCode,
    setAreaCode,
  } = props;

  const notShowOutline = showOutline === false;
  const [focused, setFocused] = useState(false);

  const { language } = useLanguageStore();

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
      }}
    >
      {desc && (
        <div
          style={{
            flex: descWidth ? `0 0 ${descWidth}px` : '0 0 80px',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.55)',
          }}
        >
          {desc}
        </div>
      )}
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{ display: 'flex' }}>
          {type === 'phone' ? (
            <SingleSelect
              placeHolder="Region"
              value={areaCode}
              optsElements={AREA_CODE}
              onClick={setAreaCode}
            />
          ) : null}
          <input
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
            }}
            style={{
              flex: '1',
              background: 'none',
              paddingLeft: '12px',
              paddingRight: '12px',
              outline: 'none',
              margin: '0',
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center',
              border: notShowOutline
                ? ''
                : errMsg && errMsg !== ''
                  ? '1px solid rgba(250,81,81,1)'
                  : focused
                    ? '1px solid rgba(7,193,96,0.3)'
                    : '1px solid rgba(0,0,0,0.1)',
              borderRadius: '8px',
              height: '40px',
              color: 'rgba(0,0,0,0.9)',
              fontSize: '14px',
              fontWeight: '400',
              width: '100%',
            }}
            onChange={onChange}
            className="eduInput"
            type={type === 'password' ? 'password' : 'text'}
            value={value}
            placeholder={placeHolder}
            defaultValue={defaultValue}
          />
        </div>
        {errMsg && (
          <p
            style={{
              fontSize: '14px',
              color: 'rgba(250,81,81,1)',
              position: 'absolute',
            }}
          >
            {errMsg}
          </p>
        )}
      </div>
    </div>
  );
}

interface ICheckBoxProps {
  value: boolean;
  onChange: () => void;
}
export function CheckBox(props: ICheckBoxProps) {
  const { value, onChange } = props;
  return (
    <div
      style={{
        width: '16px',
        height: '16px',
        borderRadius: '4px',
        backgroundColor: value ? 'rgba(0,195,131,1)' : '',
        border: value ? '' : '1px solid rgba(0,0,0,0.3)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onClick={onChange}
    >
      {value && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.65685 18.435L3 12.7782L4.41421 11.364L9.36396 16.3137L20.6777 5L22.0919 6.41421L10.0711 18.435C9.68054 18.8256 9.04738 18.8256 8.65685 18.435Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
}

interface IMultipleSelectInputBox {
  desc?: string | ReactNode;
  placeHolder?: string | ReactNode;
  value?: string[];
  onChange?: (item: string) => void;
  // options?: string[]
  displayNum: number;
  optsElements?: any;
  optsWidth?: number;
}

function MultipleSelectInputBox(props: IMultipleSelectInputBox) {
  const {
    desc,
    placeHolder,
    value,
    onChange,
    displayNum,
    optsElements,
    optsWidth,
  } = props;

  const [focused, setFocused] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (divRef.current && !divRef.current.contains(event.target as Node)) {
        setFocused(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [divRef]);

  return (
    <div
      ref={divRef}
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        height: '40px',
        position: 'relative',
      }}
    >
      {desc && (
        <div
          style={{
            flex: '0 0 80px',
            fontSize: '14px',
            fontWeight: '400',
            color: 'rgba(0,0,0,0.55)',
          }}
        >
          {desc}
        </div>
      )}
      <div style={{ width: '100%', position: 'relative' }}>
        <div
          style={{
            flex: '1',
            background: 'none',
            paddingLeft: '12px',
            paddingRight: '12px',
            outline: 'none',
            margin: '0',
            boxShadow: 'none',
            display: 'flex',
            alignItems: 'center',
            border: focused
              ? '1px solid rgba(7,193,96,0.3)'
              : '1px solid rgba(0,0,0,0.1)',
            borderRadius: '8px',
            height: '40px',
            color: 'rgba(0,0,0,0.9)',
            fontSize: '14px',
            fontWeight: '400',
          }}
          className="eduInput"
          onClick={() => {
            setFocused(!focused);
          }}
        >
          {placeHolder && value?.length === 0 && (
            <span
              style={{
                fontSize: '14px',
                fontWeight: '400',
                color: 'rgba(0,0,0,0.3)',
                width: '100%',
              }}
            >
              {placeHolder}
            </span>
          )}
          {value && value?.length !== 0 && (
            <div style={{ width: '100%', display: 'flex' }}>
              {value.slice(0, displayNum).map((item) => {
                return (
                  <div
                    key={item}
                    style={{
                      fontSize: '12px',
                      height: '21px',
                      color: 'rgba(0,0,0,0.9)',
                      paddingLeft: '6px',
                      paddingRight: '4px',
                      backgroundColor: 'rgba(0,0,0,0.05)',
                      display: 'flex',
                      alignItems: 'center',
                      borderRadius: '2px',
                      marginRight: '4px',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (onChange) {
                        onChange(item);
                      }
                    }}
                  >
                    <span>{item}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 25 25"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.9987 13.0605L5.55857 19.4994L4.49801 18.4386L10.9379 11.9999L4.49707 5.56024L5.55763 4.49948L11.9987 10.9394L18.4423 4.49695L19.5029 5.55771L13.0594 11.9999L19.5029 18.4423L18.4424 19.503L11.9987 13.0605Z"
                        fill="rgba(0,0,0,0.5)"
                      />
                    </svg>
                  </div>
                );
              })}

              {value.length > displayNum && (
                <div
                  style={{
                    fontSize: '12px',
                    height: '21px',
                    color: 'rgba(0,0,0,0.9)',
                    paddingLeft: '6px',
                    paddingRight: '6px',
                    backgroundColor: 'rgba(0,0,0,0.05)',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '2px',
                    marginRight: '4px',
                  }}
                >
                  <span>+{value.length - displayNum}</span>
                </div>
              )}
            </div>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <svg
              style={{
                transform: focused ? 'rotate(270deg)' : 'rotate(90deg)',
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="20"
              viewBox="0 0 12 24"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.4541 6.58068L3.51476 5.52002L9.29366 11.2989C9.68378 11.689 9.68724 12.3181 9.29366 12.7117L3.51476 18.4906L2.4541 17.4299L7.87872 12.0053L2.4541 6.58068Z"
                fill="black"
              />
            </svg>
          </div>
        </div>
        {focused && (
          <div
            style={{
              position: 'absolute',
              top: '40px',
              backgroundColor: 'white',
              width: `${optsWidth ? optsWidth + 'px' : '100%'}`,
              borderRadius: '8px',
              boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
              display: 'flex',
              flexWrap: 'wrap',
              paddingTop: '8px',
              paddingBottom: '8px',
              zIndex: '10',
            }}
          >
            {optsElements}
          </div>
        )}
      </div>
    </div>
  );
}

interface IRadioInputBox {
  desc?: string;
  descWidth?: string;
  placeHolder?: string;
  value?: string;
  onChange?: (item: string) => void;
  options?: string[];
  optsElements?: any;
  errMsg?: string;
}

export interface RadioInputBoxRef {
  blur: () => void;
}

export const RadioInputBox = forwardRef<RadioInputBoxRef, IRadioInputBox>(
  (props, ref) => {
    const {
      desc,
      placeHolder,
      value,
      onChange,
      options,
      descWidth,
      errMsg,
      optsElements,
    } = props;

    const [focused, setFocused] = useState(false);
    const [hoverItem, setHoverItem] = useState('');
    const divRef = useRef<HTMLDivElement>(null);

    const blur = () => {
      setFocused(false);
    };

    useImperativeHandle(ref, () => ({
      blur,
    }));

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
          setFocused(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [divRef]);

    return (
      <div
        ref={divRef}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          height: '40px',
          position: 'relative',
        }}
      >
        {desc && (
          <div
            style={{
              flex: descWidth ? `0 0 ${descWidth}px` : '0 0 80px',
              fontSize: '14px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.55)',
            }}
          >
            {desc}
          </div>
        )}
        <div style={{ width: '100%', position: 'relative' }}>
          <div
            style={{
              flex: '1',
              background: 'none',
              paddingLeft: '12px',
              paddingRight: '12px',
              outline: 'none',
              margin: '0',
              boxShadow: 'none',
              display: 'flex',
              alignItems: 'center',
              border:
                errMsg && errMsg !== ''
                  ? '1px solid rgba(250,81,81,1)'
                  : focused
                    ? '1px solid rgba(7,193,96,0.3)'
                    : '1px solid rgba(0,0,0,0.1)',
              borderRadius: '8px',
              height: '40px',
              color: 'rgba(0,0,0,0.9)',
              fontSize: '14px',
              fontWeight: '400',
              position: 'relative',
            }}
            className="eduInput"
            onClick={() => {
              setFocused(!focused);
            }}
          >
            {placeHolder && value === '' && (
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.3)',
                  width: '100%',
                }}
              >
                {placeHolder}
              </span>
            )}
            {value && (
              <span
                style={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: 'rgba(0,0,0,0.9)',
                  width: '100%',
                }}
              >
                {value}
              </span>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <svg
                style={{
                  transform: focused ? 'rotate(270deg)' : 'rotate(90deg)',
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="20"
                viewBox="0 0 12 24"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M2.4541 6.58068L3.51476 5.52002L9.29366 11.2989C9.68378 11.689 9.68724 12.3181 9.29366 12.7117L3.51476 18.4906L2.4541 17.4299L7.87872 12.0053L2.4541 6.58068Z"
                  fill="black"
                />
              </svg>
            </div>
          </div>
          {errMsg && (
            <p
              style={{
                fontSize: '14px',
                color: 'rgba(250,81,81,1)',
                position: 'absolute',
              }}
            >
              {errMsg}
            </p>
          )}
          {focused && (
            <div
              style={{
                position: 'absolute',
                top: '40px',
                backgroundColor: 'white',
                width: '100%',
                borderRadius: '8px',
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',
                paddingTop: '8px',
                paddingBottom: '8px',
                zIndex: '10',
              }}
            >
              {optsElements}

              {options &&
                options.map((item) => {
                  return (
                    <div
                      key={item}
                      onMouseEnter={() => {
                        setHoverItem(item);
                      }}
                      onMouseLeave={() => {
                        setHoverItem('');
                      }}
                      style={{
                        paddingLeft: '12px',
                        display: 'flex',
                        alignItems: 'center',
                        height: '36px',
                        fontSize: '14px',
                        backgroundColor:
                          hoverItem === item ? 'rgba(0,195,131,0.1)' : '',
                        color:
                          hoverItem === item
                            ? 'rgba(0,195,131,1)'
                            : 'rgba(0,0,0,0.9',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (onChange) {
                          onChange(item);
                          setFocused(false);
                        }
                      }}
                    >
                      {item}
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    );
  },
);

interface IQRCodeProps {
  status: qrCodeStatus;
  word: string | ReactNode;
  setStatus: (status: qrCodeStatus) => void;
  ticket: string;
  setTicket: (ticket: string) => void;
  setRegisted: (registed: boolean) => void;
}

function QRCode(props: IQRCodeProps) {
  const preURL = process.env.PUBLIC_URL;
  const { status, word, setStatus, ticket, setTicket, setRegisted } = props;

  const getTicket = async () => {
    // 创建一个FormData对象
    const formData = new FormData();
    formData.append('action', 'getticket');
    formData.append('typeid', '295');
    formData.append('appid', 'wx3199c80279c7b8c3');

    // 发起POST请求
    const resp = await axios.post(urlConfigs.basesafeqrcode, formData);
    setTicket(resp.data.qrcheck_ticket);
    props.setTicket(resp.data.qrcheck_ticket);
    return resp.data;
  };

  useEffect(() => {
    getTicket();
  }, []);

  useEffect(() => {
    if (ticket !== '') {
      const interval = setInterval(async () => {
        const resp = await axios.get(
          `${urlConfigs.basesafeqrcode}?action=ask&qrcheck_ticket=${ticket}&appid=wx3199c80279c7b8c3`,
        );

        if (resp.data.base_resp?.ret === 0) {
          if (resp.data.status === 0) {
            // 还没扫
            setStatus('scaning');
          }
          if (resp.data.status === 4) {
            setStatus('scanSuccess');
          }
          if (resp.data.status === 2) {
            setStatus('canceled');
            if (resp.data.self_check_err_msg === '已注册过') {
              setRegisted(true);
            }
            clearInterval(interval);
          }
          if (resp.data.status === 1) {
            // 完成授权
            setStatus('done');
            clearInterval(interval);
          }
        }
      }, 2000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [ticket]);

  return (
    <div
      style={{
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '200px',
          height: '200px',
          border: '0.5px solid rgba(0,0,0,0.1)',
          borderRadius: '8px',
          backgroundColor: 'white',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <img
          src={`https://mp.weixin.qq.com/cgi-bin/basesafeqrcode?action=getqrcode&qrcheck_ticket=${ticket}&size=200&appid=wx3199c80279c7b8c3`}
          alt=""
        />

        {status === 'scanSuccess' && (
          <div
            style={{
              position: 'absolute',
              width: '200px',
              height: '200px',
              left: '0',
              top: '0',
              backgroundColor: 'rgba(255,255,255,0.94)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                backgroundImage: `url(${preURL}/svg/check-circle.svg)`,
                width: '64px',
                height: '64px',
              }}
            ></div>
            <div style={{ height: '16px' }}></div>
            <p
              style={{
                fontSize: '17px',
                color: 'rgba(0,0,0,0.9)',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id="registDialog.scanSuccess" />
            </p>
          </div>
        )}

        {status === 'canceled' && (
          <div
            style={{
              position: 'absolute',
              width: '200px',
              height: '200px',
              left: '0',
              top: '0',
              backgroundColor: 'rgba(255,255,255,0.94)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                backgroundImage: `url(${preURL}/svg/info-circle.svg)`,
                width: '64px',
                height: '64px',
              }}
            ></div>
            <div style={{ height: '16px' }}></div>
            <p
              style={{
                fontSize: '17px',
                color: 'rgba(0,0,0,0.9)',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id="registDialog.canceled" />
            </p>
          </div>
        )}
      </div>

      <p
        style={{
          fontSize: '17px',
          color: 'rgba(0,0,0,0.9)',
          fontWeight: '400',
          marginTop: '24px',
        }}
      >
        {word}
        {status !== 'scaning' && (
          <span
            style={{
              color: 'rgb(101,119,156)',
              marginLeft: '6px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setStatus('scaning');
              getTicket();
            }}
          >
            <FormattedMessage id="registDialog.repeatScan" />
          </span>
        )}
      </p>
    </div>
  );
}
