import { photoConfig } from '../config';
import '../photoBlock.css';
const PhotoTitleDesktop = () => {
  return (
    <div style={{ width: '100%', display: 'flex', height: '34px' }}>
      <div
        style={{
          width: '8px',
          height: '8px',
          backgroundColor: 'rgba(100,103,240,1)',
          marginRight: '6px',
        }}
      ></div>
      <div style={{ fontSize: '24px', fontWeight: '500', marginRight: '6px' }}>
        {photoConfig.title}
      </div>
      <div
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: 'rgba(16,174,255,1)',
          alignSelf: 'end',
        }}
      ></div>
    </div>
  );
};
export const PhotoBlockDesktop = () => {
  return (
    <>
      <PhotoTitleDesktop />
      <div className="photo-block-desktop__wrap-box">
        {photoConfig.content.map((item, index) => {
          return (
            <div
              key={`photo-block-item__${index}`}
              onClick={() => {
                window.open(item.link, '_blank');
              }}
              className="photo-block-desktop__content-box"
            >
              <img
                src={item.photo}
                alt=""
              />
              <div style={{ padding: '24px' }}>
                <div
                  className="noWrapText"
                  style={{
                    height: '28px',
                    fontSize: '20px',
                    fontWeight: '500',
                    color: 'rgba(0,0,0,0.9)',
                  }}
                >
                  {item.title}
                </div>
                <div
                  style={{
                    height: '24px',
                    marginTop: '16px',
                    marginBottom: '12px',
                    fontSize: '17px',
                    fontWeight: '400',
                    color: 'rgba(0,0,0,0.55)',
                  }}
                >
                  {item.timeLen}
                </div>
                <div style={{ height: '28px', display: 'flex' }}>
                  <img
                    src={item.authorIcon}
                    alt=""
                    style={{
                      width: '28px',
                      height: '28px',
                      marginRight: '8px',
                    }}
                  />
                  <div
                    className="noWrapText"
                    style={{
                      height: '28px',
                      fontSize: '17px',
                      fontWeight: '400',
                      color: 'rgba(0,0,0,0.55)',
                    }}
                  >
                    {item.author}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
