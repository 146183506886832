import { FormattedMessage } from 'react-intl';
const preURL = process.env.PUBLIC_URL;

export const photoConfig = {
  title: <FormattedMessage id="photoConfig.title" />,
  content: [
    {
      title: <FormattedMessage id="photoConfig.title1" />,
      author: <FormattedMessage id="photoConfig.author1" />,
      timeLen: <FormattedMessage id="photoConfig.timelen1" />,
      authorIcon: `${preURL}/img/authoricon1.png`,
      photo: `${preURL}/img/case1.png`,
      link: 'https://developers.weixin.qq.com/community/business/course/0008e094d78858e88822d4f0c6b40d',
    },
    {
      title: <FormattedMessage id="photoConfig.title2" />,
      author: <FormattedMessage id="photoConfig.author2" />,
      timeLen: <FormattedMessage id="photoConfig.timelen2" />,
      authorIcon: `${preURL}/img/authoricon2.png`,
      photo: `${preURL}/img/case2.jpg`,
      link: 'https://developers.weixin.qq.com/community/business/course/000e46b2c300a8688c22887256100d',
    },
    {
      title: <FormattedMessage id="photoConfig.title3" />,
      author: <FormattedMessage id="photoConfig.author3" />,
      timeLen: <FormattedMessage id="photoConfig.timelen3" />,
      authorIcon: `${preURL}/img/authoricon3.png`,
      photo: `${preURL}/img/case3.jpg`,
      link: 'https://developers.weixin.qq.com/community/business/course/000466d3ee0a98688e22c680c6680d',
    },
  ],
};
