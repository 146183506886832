import { photoConfig } from '../config';
const PhotoTitleMobile = () => {
  return (
    <div
      style={{
        display: 'flex',
        height: '28px',
        margin: '32px 16px 12px 16px',
      }}
    >
      <div
        style={{
          width: '6px',
          height: '6px',
          backgroundColor: 'rgba(250, 157, 59, 1)',
          marginRight: '6px',
          borderRadius: '1px',
        }}
      ></div>
      <div
        style={{
          fontSize: '15px',
          fontWeight: '500',
          marginRight: '6px',
          lineHeight: '28px',
        }}
      >
        {photoConfig.title}
      </div>
      <div
        style={{
          width: '8px',
          height: '8px',
          backgroundColor: 'rgba(16, 174, 255, 1)',
          alignSelf: 'end',
          borderRadius: '1px',
        }}
      ></div>
    </div>
  );
};
export const PhotoBlockMobile = () => {
  return (
    <>
      <PhotoTitleMobile />
      <div className="photo-block-mobile__wrap-box">
        {photoConfig.content.map((item) => {
          return (
            <div
              onClick={() => {
                window.open(item.link, '_blank');
              }}
              className="photo-block-mobile__content-box"
            >
              <img
                src={item.photo}
                alt=""
              />
              <div style={{ padding: '16px' }}>
                <div className="noWrapText photo-block-mobile__title">
                  {item.title}
                </div>
                <div className="photo-block-mobile__timelen">
                  {item.timeLen}
                </div>
                <div style={{ height: '20px', display: 'flex' }}>
                  <img
                    src={item.authorIcon}
                    alt=""
                    className="photo-block-mobile__author-icon"
                  />
                  <div className="noWrapText photo-block-mobile__author">
                    {item.author}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
